
export const environment = {
  production: false,
  // apiURL: "https://api.sparkfitness.fit/api/V1/GP/",
  apiURL: "https://api.petalyellow.com/api/V1/GP/",
  image:'https://api.petalyellow.com/member/',
  mqttConfig: {
    hostname: "sparkfitness.fit",
    port: 8084,
    path: "/mqtt",
    protocol: "wss",
    username : "SparkFrontUser"  ,
    password : "SparKPetalFront@Mq"
  }
  // mqttConfig: {
  //   hostname: "device.petalyellow.com",
  //   port: 8084,
  //   path: "/mqtt",
  //   protocol: "wss",
  //   username : "PetalYelWssUser"  ,
  //   password : "PetalYelFront@Mq"
  // },
};

