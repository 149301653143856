import { Routes } from "@angular/router";
import { AppComponent } from "../app/app.component"


export const rootRouterConfig: Routes = [
  {
    path: "",
    component: AppComponent,
    children: [
    
      {
        path: 'weight/:branchId/spark-weight-receiver-1',
        loadChildren: () => import('./weight/weight.module').then(m => m.WeightModule),
        data: { title: "Weight", breadcrumb: "Weight" }
      },
    ]
  },

];